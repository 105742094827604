/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 9, 2020 */
@font-face {
  font-family: 'montserratblack';
  src: url("../fonts/montserrat-black-webfont.eot");
  src: url("../fonts/montserrat-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-black-webfont.woff2") format("woff2"), url("../fonts/montserrat-black-webfont.woff") format("woff"), url("../fonts/montserrat-black-webfont.ttf") format("truetype"), url("../fonts/montserrat-black-webfont.svg#montserratblack") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratbold';
  src: url("../fonts/montserrat-bold-webfont.eot");
  src: url("../fonts/montserrat-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-bold-webfont.woff2") format("woff2"), url("../fonts/montserrat-bold-webfont.woff") format("woff"), url("../fonts/montserrat-bold-webfont.ttf") format("truetype"), url("../fonts/montserrat-bold-webfont.svg#montserratbold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratbold_italic';
  src: url("../fonts/montserrat-bolditalic-webfont.eot");
  src: url("../fonts/montserrat-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-bolditalic-webfont.woff2") format("woff2"), url("../fonts/montserrat-bolditalic-webfont.woff") format("woff"), url("../fonts/montserrat-bolditalic-webfont.ttf") format("truetype"), url("../fonts/montserrat-bolditalic-webfont.svg#montserratbold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratitalic';
  src: url("../fonts/montserrat-italic-webfont.eot");
  src: url("../fonts/montserrat-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-italic-webfont.woff2") format("woff2"), url("../fonts/montserrat-italic-webfont.woff") format("woff"), url("../fonts/montserrat-italic-webfont.ttf") format("truetype"), url("../fonts/montserrat-italic-webfont.svg#montserratitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratlight';
  src: url("../fonts/montserrat-light-webfont.eot");
  src: url("../fonts/montserrat-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-light-webfont.woff2") format("woff2"), url("../fonts/montserrat-light-webfont.woff") format("woff"), url("../fonts/montserrat-light-webfont.ttf") format("truetype"), url("../fonts/montserrat-light-webfont.svg#montserratlight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratmedium';
  src: url("../fonts/montserrat-medium-webfont.eot");
  src: url("../fonts/montserrat-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-medium-webfont.woff2") format("woff2"), url("../fonts/montserrat-medium-webfont.woff") format("woff"), url("../fonts/montserrat-medium-webfont.ttf") format("truetype"), url("../fonts/montserrat-medium-webfont.svg#montserratmedium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratsemibold';
  src: url("../fonts/montserrat-semibold-webfont.eot");
  src: url("../fonts/montserrat-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-semibold-webfont.woff2") format("woff2"), url("../fonts/montserrat-semibold-webfont.woff") format("woff"), url("../fonts/montserrat-semibold-webfont.ttf") format("truetype"), url("../fonts/montserrat-semibold-webfont.svg#montserratsemibold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratregular';
  src: url("../fonts/montserrat-regular-webfont.eot");
  src: url("../fonts/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-regular-webfont.woff2") format("woff2"), url("../fonts/montserrat-regular-webfont.woff") format("woff"), url("../fonts/montserrat-regular-webfont.ttf") format("truetype"), url("../fonts/montserrat-regular-webfont.svg#montserratregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratthin';
  src: url("../fonts/montserrat-thin-webfont.eot");
  src: url("../fonts/montserrat-thin-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-thin-webfont.woff2") format("woff2"), url("../fonts/montserrat-thin-webfont.woff") format("woff"), url("../fonts/montserrat-thin-webfont.ttf") format("truetype"), url("../fonts/montserrat-thin-webfont.svg#montserratthin") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'montserratextrabold';
  src: url("../fonts/montserrat-extrabold-webfont.eot");
  src: url("../fonts/montserrat-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-extrabold-webfont.woff2") format("woff2"), url("../fonts/montserrat-extrabold-webfont.woff") format("woff"), url("../fonts/montserrat-extrabold-webfont.ttf") format("truetype"), url("../fonts/montserrat-extrabold-webfont.svg#montserratextrabold") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  box-sizing: border-box; }

*:after, *:before {
  display: block;
  line-height: 1; }

[data-src] {
  opacity: 0; }
  [data-src][src] {
    opacity: 1;
    transition-duration: 400ms;
    transition-delay: 0.2s; }

.preload {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none; }
  .preload.load {
    opacity: 0;
    transition: 500ms; }
  .preload.loadR {
    opacity: 1;
    transition: 500ms; }

@keyframes sk-double-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  position: relative;
  flex-direction: column;
  font-family: "montserratregular";
  -webkit-text-size-adjust: 100%;
  color: #333333;
  padding-top: 115px;
  margin: 0; }
  @media screen and (max-width: 768px) {
    body {
      padding-top: 80px; } }

h1 {
  font-family: "montserratbold";
  font-weight: normal;
  font-size: 30px;
  color: #1b1725;
  line-height: 1;
  margin: 0 0 40px; }
  h1 a:hover {
    color: #6c1b34; }
  @media screen and (max-width: 1440px) {
    h1 {
      font-size: 28px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 24px; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 22px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 20px; } }

h2 {
  font-family: "montserratbold";
  font-size: 30px;
  font-weight: normal;
  color: #1b1725;
  margin: 0 0 40px; }
  h2 a:hover {
    color: #6c1b34; }
  @media screen and (max-width: 1440px) {
    h2 {
      font-size: 28px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 24px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 22px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20px; } }

h3 {
  font-family: "montserratsemibold";
  font-size: 26px;
  font-weight: normal;
  color: #1b1725;
  margin: 0 0 30px; }
  @media screen and (max-width: 1440px) {
    h3 {
      font-size: 24px; } }
  @media screen and (max-width: 1200px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 20px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 18px;
      margin-bottom: 15px; } }

h4 {
  font-family: "montserratsemibold";
  font-size: 22px;
  font-weight: normal;
  color: #1b1725; }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-family: "montserratsemibold";
  font-size: 18px;
  font-weight: normal;
  color: #1b1725;
  margin: 25px 0; }
  @media screen and (max-width: 1024px) {
    h5 {
      font-size: 16px; } }

h6 {
  font-family: "montserratsemibold";
  font-size: 16px;
  font-weight: normal;
  color: #1b1725; }

p {
  margin: 0 0 15px; }

a {
  color: #333333;
  transition: 300ms;
  text-decoration: none; }
  a:focus {
    color: #6c1b34;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #333333; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.wrapper, .page {
  position: relative;
  flex: 1 0 auto;
  font-size: 16px;
  line-height: 1.2; }
  .wrapper textarea,
  .wrapper input[type="text"],
  .wrapper input[type="email"],
  .wrapper input[type="search"],
  .wrapper input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    min-height: 50px;
    width: 100%;
    background-color: #fff;
    font-family: "montserratregular";
    font-size: 16px;
    margin-bottom: 15px;
    box-shadow: none;
    border: 1px solid #d3dded;
    padding: 15px 30px 14px;
    border-radius: 3px; }
    .wrapper textarea::placeholder,
    .wrapper input[type="text"]::placeholder,
    .wrapper input[type="email"]::placeholder,
    .wrapper input[type="search"]::placeholder,
    .wrapper input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #999999; }
  @media screen and (max-width: 1440px) {
    .wrapper textarea,
    .wrapper input[type="text"],
    .wrapper input[type="email"],
    .wrapper input[type="search"],
    .wrapper input[type="password"], .page textarea,
    .page input[type="text"],
    .page input[type="email"],
    .page input[type="search"],
    .page input[type="password"] {
      min-height: 45px;
      padding: 13px 20px 12px; } }
  @media screen and (max-width: 1024px) {
    .wrapper textarea,
    .wrapper input[type="text"],
    .wrapper input[type="email"],
    .wrapper input[type="search"],
    .wrapper input[type="password"], .page textarea,
    .page input[type="text"],
    .page input[type="email"],
    .page input[type="search"],
    .page input[type="password"] {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px 9px; } }

.wrapper .container {
  padding-top: 90px;
  padding-bottom: 90px; }
  @media screen and (max-width: 1440px) {
    .wrapper .container {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 1280px) {
    .wrapper .container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 1024px) {
    .wrapper .container {
      padding-top: 30px;
      padding-bottom: 30px; } }

.wrapper h1 {
  text-align: center; }

.container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%; } }

.close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 10px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #3580a9;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }

.btn {
  display: table;
  height: 50px;
  width: auto !important;
  background-color: #3caee4 !important;
  font-family: "montserratsemibold" !important;
  font-size: 14px;
  color: #fff !important;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px !important;
  border: 2px solid #3caee4;
  padding: 16px 40px !important;
  line-height: 1;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: #3caee4 !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #fff !important;
    color: #3caee4 !important;
    text-decoration: none; }
  .btn.btn-tr {
    background-color: #fff !important;
    border: 1px solid #6c1b34;
    color: #6c1b34 !important; }
    .btn.btn-tr:hover {
      background-color: #6c1b34 !important;
      color: #fff !important; }
  @media screen and (max-width: 1440px) {
    .btn {
      height: 45px;
      padding: 14px 30px 13px !important; } }
  @media screen and (max-width: 1024px) {
    .btn {
      height: 40px !important;
      font-size: 14px;
      padding: 11px 25px 10px !important; } }
  @media screen and (max-width: 768px) {
    .btn {
      font-size: 12px;
      padding: 12px 20px 10px !important; } }

.check--list,
.circle--list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden; }
  .check--list li,
  .circle--list li {
    display: block;
    position: relative;
    padding-left: 25px;
    margin: 14px 0;
    margin-top: 0; }
    .check--list li:before,
    .circle--list li:before {
      content: '\f00c';
      position: absolute;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #3580a9;
      left: 0;
      top: 0px; }

.circle--list li {
  padding-left: 18px; }
  .circle--list li:before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #3cafe5;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 6px; }

.col--location,
.col--phone,
.col--email,
.col--time {
  position: relative;
  padding-left: 23px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after,
  .col--time:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 16px;
    color: #3cafe5;
    position: absolute;
    top: 0px;
    left: 0; }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095';
  top: 1px; }

.col--phone a {
  display: table;
  margin-bottom: 3px; }

.col--email:after {
  content: '\f0e0';
  font-size: 13px;
  top: 3px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

.col--time:after {
  content: '\f017'; }

.bg-blue {
  background-color: #ebf1fb; }

.radius {
  border-radius: 4px;
  overflow: hidden; }

.italic {
  font-family: "montserratitalic"; }

.n-pad {
  padding-bottom: 0 !important; }

.b-pad {
  padding-bottom: 90px !important; }
  @media screen and (max-width: 1440px) {
    .b-pad {
      padding-bottom: 70px !important; } }
  @media screen and (max-width: 1280px) {
    .b-pad {
      padding-bottom: 50px !important; } }
  @media screen and (max-width: 1024px) {
    .b-pad {
      padding-bottom: 30px !important; } }

.t-pad {
  padding-top: 90px !important; }
  @media screen and (max-width: 1440px) {
    .t-pad {
      padding-top: 70px !important; } }
  @media screen and (max-width: 1280px) {
    .t-pad {
      padding-top: 50px !important; } }
  @media screen and (max-width: 1024px) {
    .t-pad {
      padding-top: 30px !important; } }

.t-mar {
  margin-top: 90px; }
  @media screen and (max-width: 1440px) {
    .t-mar {
      margin-top: 70px; } }
  @media screen and (max-width: 1280px) {
    .t-mar {
      margin-top: 50px; } }
  @media screen and (max-width: 1024px) {
    .t-mar {
      margin-top: 30px; } }

.n-mar {
  margin-bottom: -80px; }
  @media screen and (max-width: 1440px) {
    .n-mar {
      margin-bottom: -60px; } }
  @media screen and (max-width: 1280px) {
    .n-mar {
      margin-bottom: -50px; } }
  @media screen and (max-width: 1024px) {
    .n-mar {
      margin-bottom: -30px; } }

.brown {
  color: #6c1b34; }

.socials {
  display: flex; }
  .socials li {
    margin: 0 15px 0 0; }
    .socials li a {
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      background-color: #ebf1fb;
      font-size: 22px;
      color: #6c1b34;
      text-align: center;
      border-radius: 100%; }
      .socials li a:hover {
        background-color: #6c1b34;
        color: #ebf1fb; }
  @media screen and (max-width: 768px) {
    .socials li a {
      width: 40px;
      height: 40px;
      font-size: 18px; } }

.bold {
  font-family: "montserratbold"; }

/* header */
header {
  width: 100%;
  position: fixed;
  background-color: #6c1b34;
  color: #fff;
  top: 0;
  left: 0;
  z-index: 9;
  line-height: 1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  /* header phones */
  /* header phones */
  /* header slogan */
  /* header slogan */
  /* header nav */
  /* header nav */ }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header a {
    color: #fff; }
    header a:focus {
      color: #fff; }
    header a:hover {
      color: #fff; }
  header .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  header > .container {
    height: 80px; }
  header .down {
    background-color: #4f2133; }
  header .phones .close {
    display: none; }
  header .phones a {
    font-family: "montserratsemibold";
    font-size: 18px; }
  header .phones ul li {
    margin-bottom: 5px; }
    header .phones ul li:last-of-type {
      margin-bottom: 0; }
  header .col--phone:after {
    top: 0; }
  header .slogan {
    font-family: "montserratlight";
    font-size: 14px;
    color: #d8c2c9; }
  header .nav {
    width: calc(100% + 80px);
    margin: 0 -40px; }
    header .nav > ul {
      display: flex;
      justify-content: space-between; }
      header .nav > ul > li {
        position: relative; }
        header .nav > ul > li > a {
          display: flex;
          height: 35px;
          justify-content: center;
          align-items: center;
          padding: 4px 40px; }
          header .nav > ul > li > a:hover {
            background-color: #272330; }
        header .nav > ul > li.active > a {
          background-color: #272330; }
    header .nav .submenu__block {
      display: none;
      width: 750px;
      background-color: #272330;
      padding: 40px 0px;
      position: absolute;
      left: 0;
      top: 100%;
      right: 0;
      margin: 0 auto;
      z-index: 1;
      border-radius: 2px solid #3d3945; }
      header .nav .submenu__block .row {
        display: flex;
        flex-wrap: wrap; }
      header .nav .submenu__block .col {
        width: 50%;
        padding: 0 55px 0 20px;
        border-right: 2px solid #3d3945; }
        header .nav .submenu__block .col:last-of-type {
          border: none; }
      header .nav .submenu__block ul li a {
        display: block;
        font-size: 16px;
        padding: 10px 20px; }
        header .nav .submenu__block ul li a:hover {
          background-color: #1b1725;
          color: #fff; }
      header .nav .submenu__block ul li.active a, header .nav .submenu__block ul li.hover a {
        background-color: #1b1725;
        color: #fff; }
      header .nav .submenu__block .sub__title {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        color: #7e7c81;
        margin: 0 20px 15px; }
  @media screen and (max-width: 1280px) {
    header .nav {
      width: 100%;
      margin: 0; }
      header .nav > ul > li > a {
        padding: 4px 25px; } }
  @media screen and (max-width: 1024px) {
    header .logo {
      width: 180px; }
      header .logo img {
        width: 100%; }
    header .phones li a {
      font-size: 16px; }
    header .nav .submenu__block {
      width: 650px;
      max-height: 550px;
      overflow: auto;
      padding: 20px 0; }
      header .nav .submenu__block .col {
        padding: 0 15px 0 15px; }
      header .nav .submenu__block ul li a {
        font-size: 14px;
        padding: 10px 15px; }
      header .nav .submenu__block .sub__title {
        margin: 0 15px 15px; } }
  @media screen and (max-width: 768px) {
    header > .container {
      flex-direction: column;
      justify-content: center; }
    header .nav--btn {
      width: 35px;
      height: 30px;
      position: absolute;
      left: 10px;
      top: 50%;
      padding: 0;
      margin: 0;
      outline: none;
      float: none;
      transform: translateY(-50%); }
      header .nav--btn span {
        position: absolute;
        left: 0;
        right: 0;
        width: 85%;
        height: 3px;
        margin: 0 auto;
        background-color: #fff;
        transition: 350ms; }
        header .nav--btn span:nth-of-type(1) {
          top: 7px; }
        header .nav--btn span:nth-of-type(2) {
          top: 14px; }
        header .nav--btn span:nth-of-type(3) {
          top: 21px; }
      header .nav--btn.open span:nth-of-type(1) {
        top: 14px;
        transform: rotate(45deg); }
      header .nav--btn.open span:nth-of-type(2) {
        opacity: 0; }
      header .nav--btn.open span:nth-of-type(3) {
        top: 14px;
        transform: rotate(-45deg); }
    header .nav {
      display: none;
      width: 100%;
      background-color: #6c1b34;
      max-height: calc(100% - 80px);
      position: fixed;
      top: 80px;
      left: 0;
      right: 0;
      overflow: auto;
      padding: 10px 15px;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff; }
      header .nav > ul {
        flex-direction: column; }
        header .nav > ul > li > a {
          height: auto;
          padding: 10px 15px; }
      header .nav .submenu__block {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #fff; }
        header .nav .submenu__block .row {
          flex-direction: column;
          border: none; }
        header .nav .submenu__block .col {
          width: 100%;
          margin-bottom: 20px; }
          header .nav .submenu__block .col:last-of-type {
            margin-bottom: 0; }
    header .logo {
      height: 45px;
      margin: 0 auto; }
      header .logo img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center; }
    header .phones {
      display: none;
      background-color: #4f2132;
      padding: 15px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      justify-content: center;
      text-align: center;
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
      z-index: 1; }
      header .phones .close {
        display: block; }
        header .phones .close:before, header .phones .close:after {
          background-color: #fff; }
      header .phones:after {
        display: none; }
      header .phones ul li {
        display: table;
        margin: 10px auto !important; }
        header .phones ul li a {
          display: block;
          margin: 0; }
    header .slogan {
      font-size: 11px;
      margin-top: 7px; }
    header .btn {
      display: none; }
    header .phones--btn {
      font-family: 'fontAwesome';
      font-size: 20px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: #fff;
      text-decoration: none; }
      header .phones--btn:after {
        content: '\f095'; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider--block {
  position: relative;
  overflow: hidden; }

.slider {
  margin-bottom: 0px !important; }
  .slider .slick-slide {
    overflow: hidden;
    position: relative;
    height: calc(100vh - 115px);
    outline: none; }
    .slider .slick-slide:after {
      content: '';
      background: linear-gradient(to bottom, rgba(27, 23, 37, 0), rgba(27, 23, 37, 0.8));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .slider .slick-slide .slider__block {
      width: 600px;
      font-family: "montserratmedium";
      font-size: 18px;
      position: absolute;
      left: 50%;
      right: 0;
      top: 50%;
      color: #fff;
      transform: translate(-570px, -50%);
      z-index: 1; }
      .slider .slick-slide .slider__block .slider__title {
        display: block;
        font-family: "montserratbold";
        font-size: 48px;
        line-height: 1.2;
        margin-bottom: 20px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 45px; }
  .slider.arrow .slick-arrow {
    width: 44px;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    top: auto;
    bottom: 36px;
    transform: translate(0); }
    .slider.arrow .slick-arrow:after {
      color: #fff; }
    .slider.arrow .slick-arrow:hover {
      background-color: #fff; }
      .slider.arrow .slick-arrow:hover:after {
        color: #6c1b34; }
    .slider.arrow .slick-arrow.slick-prev {
      left: 90px; }
    .slider.arrow .slick-arrow.slick-next {
      right: 90px; }
  @media screen and (max-width: 1440px) {
    .slider.arrow .slick-arrow.slick-prev {
      left: 40px; }
    .slider.arrow .slick-arrow.slick-next {
      right: 40px; }
    .slider .slick-slide .slider__block {
      top: 45%; }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 40px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 25px; } }
  @media screen and (max-width: 1366px) {
    .slider.arrow .slick-arrow {
      bottom: 22px; }
      .slider.arrow .slick-arrow.slick-prev {
        left: 25px; }
      .slider.arrow .slick-arrow.slick-next {
        right: 25px; }
    .slider .slick-slide .slider__block {
      left: 100px;
      transform: translateY(-50%); }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 32px; } }
  @media screen and (max-width: 1024px) {
    .slider .slick-slide .slider__block .slider__title {
      font-size: 24px; }
    .slider.arrow .slick-arrow {
      width: 40px;
      height: 40px;
      bottom: 27px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide {
      height: 350px; }
      .slider .slick-slide .slider__block {
        width: auto;
        left: 75px;
        right: 75px;
        font-size: 16px; }
        .slider .slick-slide .slider__block .slider__title {
          font-size: 22px;
          margin-bottom: 15px; }
    .slider.arrow .slick-arrow {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%); }
      .slider.arrow .slick-arrow.slick-prev {
        left: 15px; }
      .slider.arrow .slick-arrow.slick-next {
        right: 15px; } }
  @media screen and (max-width: 600px) {
    .slider .slick-slide {
      height: 300px; }
      .slider .slick-slide .slider__block {
        left: 60px;
        right: 60px; }
        .slider .slick-slide .slider__block .slider__title {
          font-size: 18px; }
        .slider .slick-slide .slider__block .btn {
          margin-top: 20px; }
    .slider.arrow .slick-arrow {
      width: 35px;
      height: 35px; }
      .slider.arrow .slick-arrow:after {
        font-size: 20px;
        line-height: 20px; }
      .slider.arrow .slick-arrow.slick-prev {
        left: 10px; }
      .slider.arrow .slick-arrow.slick-next {
        right: 10px; } }
  @media screen and (max-width: 480px) {
    .slider .slick-slide {
      height: auto; }
      .slider .slick-slide > img {
        height: 200px; }
      .slider .slick-slide .slider__block {
        text-align: center;
        background-color: #6c1b34;
        padding: 20px 15px;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(0); }
      .slider .slick-slide .btn {
        margin-left: auto;
        margin-right: auto; }
    .slider.arrow .slick-arrow {
      top: 95px; } }
  @media screen and (max-width: 380px) {
    .slider .slick-slide > img {
      height: 150px; }
    .slider.arrow .slick-arrow {
      top: 80px; } }

.slider-progress {
  width: 1180px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  bottom: 115px;
  left: 0;
  right: 0;
  margin: 0 auto; }
  .slider-progress .progress {
    width: 0%;
    height: 2px;
    background: #3cafe5; }
  @media screen and (max-width: 1366px) {
    .slider-progress {
      width: auto;
      left: 100px;
      right: 100px;
      bottom: 95px; } }
  @media screen and (max-width: 1024px) {
    .slider-progress {
      bottom: 90px;
      left: 75px;
      right: 75px; } }
  @media screen and (max-width: 768px) {
    .slider-progress {
      left: 0;
      right: 0;
      bottom: 0; } }

.slider__nav {
  width: 1180px;
  position: absolute;
  bottom: 38px;
  left: 0;
  right: 0;
  margin: 0 auto; }
  .slider__nav .slick-track {
    display: flex; }
  .slider__nav .slick-slide {
    outline: none; }
  .slider__nav .slider__item {
    display: flex;
    height: 40px;
    font-family: "montserratsemibold";
    font-weight: normal;
    color: #fff;
    align-items: flex-start;
    opacity: 0.5;
    padding: 0 15px;
    border-right: 2px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: 300ms; }
    .slider__nav .slider__item:last-of-type {
      border-right: none; }
    .slider__nav .slider__item.slick-current, .slider__nav .slider__item:hover {
      opacity: 1; }
  @media screen and (max-width: 1366px) {
    .slider__nav {
      width: auto;
      left: 100px;
      right: 100px;
      bottom: 25px; } }
  @media screen and (max-width: 1024px) {
    .slider__nav {
      font-size: 14px;
      left: 75px;
      right: 75px; } }
  @media screen and (max-width: 768px) {
    .slider__nav {
      display: none; } }

.arrow .slick-arrow {
  width: 20px;
  height: 20px;
  z-index: 1;
  transition: 300ms; }
  .arrow .slick-arrow:before {
    display: none; }
  .arrow .slick-arrow:after {
    content: '\f104';
    font-family: 'fontAwesome';
    font-size: 28px;
    color: #6c1b34;
    transition: 300ms;
    line-height: 24px; }
  .arrow .slick-arrow.slick-prev {
    left: 0; }
  .arrow .slick-arrow.slick-next {
    right: 0; }
    .arrow .slick-arrow.slick-next:after {
      transform: rotate(-180deg); }
  .arrow .slick-arrow:hover {
    opacity: 0.7; }

.arrow.arrow-right .slick-arrow {
  top: -70px;
  transform: translate(0); }
  .arrow.arrow-right .slick-arrow.slick-prev {
    right: 50%;
    left: auto;
    margin-right: -530px; }
    .arrow.arrow-right .slick-arrow.slick-prev:after {
      margin-top: -2px; }
  .arrow.arrow-right .slick-arrow.slick-next {
    right: 50%;
    margin-right: -570px; }

@media screen and (max-width: 1440px) {
  .arrow.arrow-right .slick-arrow {
    top: -58px; } }

@media screen and (max-width: 1200px) {
  .arrow.arrow-right .slick-arrow {
    top: -55px; }
    .arrow.arrow-right .slick-arrow.slick-next {
      right: 15px;
      margin: 0; }
    .arrow.arrow-right .slick-arrow.slick-prev {
      right: 50px;
      margin: 0; } }

@media screen and (max-width: 1024px) {
  .arrow.arrow-right .slick-arrow {
    top: -40px; } }

@media screen and (max-width: 768px) {
  .arrow.arrow-right .slick-arrow {
    top: -38px; }
    .arrow.arrow-right .slick-arrow.slick-prev {
      right: 45px; } }

/* main slider */
/* main advantages */
.advantages h2 {
  text-align: center; }

@media screen and (max-width: 1024px) {
  .advantages h1 {
    margin-bottom: 20px; } }

.advantages__block {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #d1e7f8; }
  .advantages__block.up {
    padding-bottom: 30px;
    margin: 0 0 30px; }
  @media screen and (max-width: 1024px) {
    .advantages__block.up {
      padding-bottom: 20px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    .advantages__block {
      flex-wrap: wrap; }
      .advantages__block.up {
        padding-bottom: 0px;
        margin: 0;
        border: none; } }

.advantages__item {
  display: flex;
  max-width: 35%;
  min-height: 76px;
  align-items: center;
  position: relative;
  padding-left: 115px;
  padding-right: 15px; }
  .advantages__item.center {
    padding-right: 30px;
    border-left: 2px solid #d1e7f8;
    border-right: 2px solid #d1e7f8; }
  .advantages__item .icon {
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translateY(-50%); }
  .advantages__item h5 {
    font-family: "montserratsemibold";
    margin: 0; }
  @media screen and (max-width: 1024px) {
    .advantages__item {
      min-height: 65px;
      padding-left: 90px; }
      .advantages__item .icon {
        width: 50px;
        height: 60px;
        left: 20px; }
        .advantages__item .icon img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center; } }
  @media screen and (max-width: 768px) {
    .advantages__item {
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      min-height: 60px;
      text-align: center;
      padding-right: 0;
      border: none;
      padding: 0;
      margin-bottom: 15px; }
      .advantages__item.center {
        border: none;
        padding-right: 0; }
      .advantages__item .icon {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 auto;
        transform: translate(0);
        margin-bottom: 10px; } }

.advantages__down {
  display: flex;
  padding: 0 90px; }
  .advantages__down .col {
    width: 50%;
    padding-right: 50px;
    border-right: 2px solid #d1e7f8; }
    .advantages__down .col:last-of-type {
      border-right: none;
      padding-right: 0;
      padding-left: 50px; }
  .advantages__down .advantages__item {
    max-width: 100%;
    width: 100%;
    padding-right: 0;
    margin: 10px 0; }
  @media screen and (max-width: 1024px) {
    .advantages__down {
      padding: 0; }
      .advantages__down .col {
        padding-right: 20px; }
        .advantages__down .col:last-of-type {
          padding-left: 20px; } }
  @media screen and (max-width: 768px) {
    .advantages__down {
      flex-wrap: wrap; }
      .advantages__down .col {
        width: 100%;
        padding: 0 !important;
        border: none; } }

/* main advantages */
/* main services */
.services h2 {
  text-align: center; }

.services__block {
  padding: 0 80px; }
  @media screen and (max-width: 1024px) {
    .services__block {
      padding: 0 30px; } }
  @media screen and (max-width: 768px) {
    .services__block {
      padding: 0 15px; } }

.services__nav {
  width: 100%;
  border: 2px solid #ebf1fb;
  border-radius: 3px;
  margin-bottom: 40px; }
  .services__nav .services__item {
    height: 160px;
    font-family: "montserratmedium";
    color: #1b1725;
    text-align: center;
    position: relative;
    padding: 25px 15px;
    border-right: 2px solid #ebf1fb;
    transition: 300ms;
    overflow: hidden;
    cursor: pointer; }
    .services__nav .services__item img {
      max-width: 80px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      transition: 300ms; }
      .services__nav .services__item img.h {
        opacity: 0; }
    .services__nav .services__item:hover, .services__nav .services__item.slick-current {
      background-color: #6c1b34;
      color: #fff; }
      .services__nav .services__item:hover img.v, .services__nav .services__item.slick-current img.v {
        opacity: 0; }
      .services__nav .services__item:hover img.h, .services__nav .services__item.slick-current img.h {
        opacity: 1; }
  @media screen and (max-width: 1024px) {
    .services__nav {
      margin-bottom: 20px; }
      .services__nav .services__item {
        height: 140px;
        font-size: 14px;
        padding: 20px 15px; } }
  @media screen and (max-width: 768px) {
    .services__nav .services__item {
      height: 120px; }
      .services__nav .services__item img {
        max-height: 50px; } }

.services__for .btn {
  display: inline-block;
  vertical-align: top;
  margin: 35px 20px 0 0; }

.services__for .slick-slide {
  outline: none; }

.services__for .services__img img {
  display: block;
  width: 100%; }

.services__for h3 {
  font-family: "montserratbold";
  font-size: 24px;
  margin: 40px 0 20px; }

.services__for.arrow .slick-arrow {
  top: -125px; }
  .services__for.arrow .slick-arrow.slick-prev {
    left: -30px; }
  .services__for.arrow .slick-arrow.slick-next {
    right: -30px; }

@media screen and (max-width: 1024px) {
  .services__for h3 {
    font-size: 20px;
    margin: 30px 0 20px; }
  .services__for .btn {
    margin: 15px 15px 0 0; }
  .services__for.arrow .slick-arrow {
    top: -98px; } }

@media screen and (max-width: 768px) {
  .services__for.arrow .slick-arrow {
    top: -88px; }
    .services__for.arrow .slick-arrow.slick-prev {
      left: -25px; }
    .services__for.arrow .slick-arrow.slick-next {
      right: -25px; } }

@media screen and (max-width: 600px) {
  .services__for h3 {
    font-size: 16px;
    margin: 20px 0 15px; } }

/* main services */
/* main car park */
.bg {
  position: relative;
  color: #fff; }
  .bg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .bg h2, .bg h1 {
    color: #fff; }
    .bg h2 a, .bg h1 a {
      color: #fff; }
  .bg .arrow .slick-arrow:after {
    color: #fff; }

.car__nav {
  width: 600px;
  transform: translateX(100px);
  margin: -67px auto 75px; }
  .car__nav .slick-slide {
    width: auto !important;
    padding-right: 20px;
    outline: none;
    padding-bottom: 15px;
    margin-right: 35px;
    color: #aa9097;
    position: relative;
    cursor: pointer;
    line-height: 1; }
    .car__nav .slick-slide:after {
      content: '';
      height: 2px;
      background-color: #3cafe5;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 100%;
      transition: 300ms; }
    .car__nav .slick-slide.slick-current {
      color: #fff; }
      .car__nav .slick-slide.slick-current:after {
        right: 0; }
  @media screen and (max-width: 1440px) {
    .car__nav {
      margin-top: -53px; } }
  @media screen and (max-width: 1200px) {
    .car__nav {
      margin-top: -50px; }
      .car__nav .slick-slide {
        padding: 0 15px 10px 0;
        margin-right: 20px; } }
  @media screen and (max-width: 1024px) {
    .car__nav {
      margin-top: -35px; }
      .car__nav .slick-slide {
        padding-right: 10px;
        margin-right: 10px; } }
  @media screen and (max-width: 768px) {
    .car__nav {
      display: none; } }

.car__for .slick-list {
  padding: 0 25% !important; }

.car__for .slick-slide {
  outline: none;
  margin: 0 100px;
  transition: 300ms;
  opacity: 0.5; }
  .car__for .slick-slide img {
    height: 290px;
    max-width: 100%;
    object-fit: contain;
    object-position: center bottom;
    margin: 0 auto; }
  .car__for .slick-slide.slick-center {
    opacity: 1; }
    .car__for .slick-slide.slick-center .car-slider__down {
      opacity: 1; }

.car__for .car-slider__down {
  border-top: 2px solid #85485c;
  margin-top: 50px;
  transition: 300ms;
  opacity: 0; }
  .car__for .car-slider__down .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 30px 0 15px; }
  .car__for .car-slider__down h3, .car__for .car-slider__down h6 {
    color: #fff;
    margin: 0; }

.car__for.arrow.arrow-right .slick-arrow {
  top: -110px; }

@media screen and (max-width: 1680px) {
  .car__for .slick-list {
    padding: 0 22% !important; }
  .car__for .slick-slide {
    margin: 0 75px; } }

@media screen and (max-width: 1440px) {
  .car__for .slick-list {
    padding: 0 18% !important; }
  .car__for .slick-slide {
    margin: 0 50px; } }

@media screen and (max-width: 1200px) {
  .car__for .slick-list {
    padding: 0 15px !important; }
  .car__for .slick-slide {
    margin: 0; }
  .car__for.arrow.arrow-right .slick-arrow {
    top: -107px; } }

@media screen and (max-width: 1024px) {
  .car__for .slick-slide img {
    height: 200px; }
  .car__for .car-slider__down {
    margin-top: 30px; }
    .car__for .car-slider__down .row {
      padding: 15px 0; }
  .car__for.arrow.arrow-right .slick-arrow {
    top: -105px; } }

@media screen and (max-width: 768px) {
  .car__for.arrow.arrow-right .slick-arrow {
    top: -38px; } }

@media screen and (max-width: 600px) {
  .car__for .slick-slide img {
    height: 150px; }
  .car__for .car-slider__down {
    margin-top: 20px; }
    .car__for .car-slider__down h3 {
      font-size: 16px; }
    .car__for .car-slider__down h6 {
      font-size: 14px; } }

@media screen and (max-width: 540px) {
  .car__for .car-slider__down .row {
    flex-direction: column; }
  .car__for .car-slider__down h3 {
    margin: 0 0 10px; }
  .car__for .car-slider__down h6 {
    margin-bottom: 10px; }
  .car__for .car-slider__down .btn {
    margin-top: 10px; } }

.car-park {
  padding-bottom: 80px; }
  .car-park .container {
    padding-bottom: 0; }
  @media screen and (max-width: 1440px) {
    .car-park {
      padding-bottom: 60px; } }
  @media screen and (max-width: 1200px) {
    .car-park {
      padding-bottom: 50px; } }
  @media screen and (max-width: 1024px) {
    .car-park {
      padding-bottom: 30px; } }

/* main car park */
/* main shares */
.shares .more {
  float: right; }

.shares__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .shares__block .shares__item {
    width: calc(50% - 15px);
    margin-bottom: 60px; }
    .shares__block .shares__item:nth-last-of-type(-n+2) {
      margin-bottom: 0px; }
    .shares__block .shares__item .shares__img {
      display: flex;
      background: url(../img/img-27.jpg) no-repeat center center;
      background-size: cover;
      height: 310px;
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 1;
      margin-bottom: 25px; }
      .shares__block .shares__item .shares__img > img {
        position: relative;
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: right bottom; }
      .shares__block .shares__item .shares__img .shares__stiker {
        position: absolute;
        left: 30px;
        top: 35px;
        color: #ffffff;
        line-height: 1; }
        .shares__block .shares__item .shares__img .shares__stiker h3 {
          font-family: "montserratsemibold";
          font-size: 18px;
          color: #fff;
          margin: 10px 0; }
        .shares__block .shares__item .shares__img .shares__stiker h2 {
          color: #3cafe5;
          text-transform: uppercase;
          margin: 10px 0; }
    .shares__block .shares__item p a {
      font-family: "montserratsemibold";
      color: #6c1b34;
      text-decoration: underline; }
      .shares__block .shares__item p a:hover {
        text-decoration: none; }
    .shares__block .shares__item .btn {
      margin-top: 25px; }
  @media screen and (max-width: 1024px) {
    .shares__block .shares__item {
      width: calc(50% - 7.5px);
      margin-bottom: 30px; }
      .shares__block .shares__item .shares__img {
        height: 275px; }
        .shares__block .shares__item .shares__img > img {
          max-height: 80%; }
        .shares__block .shares__item .shares__img .shares__stiker {
          left: 15px;
          top: 15px; }
          .shares__block .shares__item .shares__img .shares__stiker h2 {
            font-size: 20px; }
          .shares__block .shares__item .shares__img .shares__stiker h3 {
            font-size: 16px; } }
  @media screen and (max-width: 768px) {
    .shares__block .shares__item .shares__img {
      height: 200px; }
      .shares__block .shares__item .shares__img > img {
        max-height: 70%; }
      .shares__block .shares__item .shares__img .shares__stiker {
        top: 10px; }
        .shares__block .shares__item .shares__img .shares__stiker h2 {
          font-size: 18px; }
        .shares__block .shares__item .shares__img .shares__stiker h3 {
          font-size: 14px; } }
  @media screen and (max-width: 600px) {
    .shares__block .shares__item {
      width: 100%;
      margin-bottom: 25px; }
      .shares__block .shares__item:nth-last-of-type(2n) {
        margin-bottom: 25px; }
      .shares__block .shares__item:last-of-type {
        margin-bottom: 0 !important; }
      .shares__block .shares__item .shares__img {
        height: 250px;
        margin-bottom: 20px; } }
  @media screen and (max-width: 480px) {
    .shares__block .shares__item .shares__img {
      height: 200px; }
    .shares__block .shares__item p a {
      display: table;
      margin-top: 5px; } }

.more {
  font-family: "montserratsemibold";
  font-size: 14px;
  color: #6c1b34;
  text-transform: uppercase;
  position: relative;
  padding-right: 20px;
  margin-top: -65px; }
  .more:after {
    content: '\f101';
    font-family: 'fontAwesome';
    font-size: 18px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
  @media screen and (max-width: 1440px) {
    .more {
      margin-top: -55px; } }
  @media screen and (max-width: 1024px) {
    .more {
      margin-top: -36px; } }
  @media screen and (max-width: 768px) {
    .more {
      padding-right: 15px;
      font-size: 12px;
      margin-top: -32px; }
      .more:after {
        font-size: 16px;
        top: 45%; } }

.page .reviews__item {
  border: 2px solid #ebf1fb; }
  .page .reviews__item .reviews__up {
    border-bottom: 2px solid #ebf1fb; }

/* main sheres */
/* main partners */
.partners h2 {
  padding-right: 50px; }

.partners__slider .slick-list {
  padding: 0 75px !important; }

.partners__slider .slick-slide {
  display: flex;
  height: 120px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebf1fb;
  padding: 10px;
  margin: 0 15px;
  transition: 300ms;
  opacity: 0.5; }
  .partners__slider .slick-slide img {
    max-width: 100%;
    max-height: 100%;
    object-position: center center;
    object-fit: contain; }
  .partners__slider .slick-slide.slick-active + .slick-active {
    opacity: 1; }
  .partners__slider .slick-slide.slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active {
    opacity: 0.5; }

@media screen and (max-width: 1680px) {
  .partners__slider .slick-list {
    padding: 0 15% !important; }
  .partners__slider .slick-slide.slick-active {
    opacity: 1; } }

@media screen and (max-width: 1440px) {
  .partners__slider .slick-list {
    padding: 0 9% !important; } }

@media screen and (max-width: 1366px) {
  .partners__slider .slick-list {
    padding: 0 6% !important; } }

@media screen and (max-width: 1200px) {
  .partners__slider .slick-list {
    padding: 0 7.5px !important; }
  .partners__slider .slick-slide {
    margin: 0 7.5px; }
    .partners__slider .slick-slide.slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active {
      opacity: 1; } }

@media screen and (max-width: 1024px) {
  .partners__slider .slick-slide {
    opacity: 1;
    height: 100px; } }

/* main partners */
/* main reviews */
.reviews h2 {
  padding-right: 50px; }

.reviews__slider .slick-list {
  padding: 0 19% !important; }

.reviews__slider .slick-track {
  display: flex; }

.reviews__slider .slick-slide {
  height: auto;
  opacity: 0.5;
  outline: none; }
  .reviews__slider .slick-slide.slick-active {
    opacity: 1; }
  .reviews__slider .slick-slide.slick-active + .slick-slide + .slick-slide {
    opacity: 0.5; }

@media screen and (max-width: 1680px) {
  .reviews__slider .slick-list {
    padding: 0 15% !important; } }

@media screen and (max-width: 1440px) {
  .reviews__slider .slick-list {
    padding: 0 9% !important; } }

@media screen and (max-width: 1366px) {
  .reviews__slider .slick-list {
    padding: 0 6% !important; } }

@media screen and (max-width: 1200px) {
  .reviews__slider .slick-list {
    padding: 0 7.5px !important; }
  .reviews__slider .slick-slide {
    opacity: 1;
    margin: 0 7.5px; } }

.reviews__item {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  margin: 0 15px; }
  .reviews__item .reviews__up {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebf1fb;
    padding: 20px 25px; }
    .reviews__item .reviews__up .name {
      font-family: "montserratbold"; }
  .reviews__item .reviews--txt {
    max-height: 155px;
    overflow: hidden;
    position: relative; }
    .reviews__item .reviews--txt:after {
      content: '';
      height: 40px;
      position: absolute;
      top: calc(100% - 40px);
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white); }
  .reviews__item .reviews__down {
    flex: 1 0 auto;
    padding: 25px 25px 55px; }
  .reviews__item .more {
    display: table;
    position: absolute;
    bottom: 25px;
    left: 25px;
    margin: 0; }
  @media screen and (max-width: 480px) {
    .reviews__item .reviews__up {
      height: auto;
      flex-direction: column; }
      .reviews__item .reviews__up .name {
        margin-bottom: 5px; } }

/* main reviews */
/* main news */
.news__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .news__block .news__item {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    margin-bottom: 30px;
    transition: 0ms; }
    .news__block .news__item:nth-last-of-type(-n+2) {
      margin-bottom: 0; }
    .news__block .news__item .news__img {
      height: 330px;
      position: relative; }
      .news__block .news__item .news__img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
      .news__block .news__item .news__img .date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 77px;
        height: 120px;
        background-color: #3cafe5;
        font-family: "montserratsemibold";
        font-size: 14px;
        color: #fff;
        text-align: center;
        position: absolute;
        top: 0;
        left: 30px;
        z-index: 1; }
        .news__block .news__item .news__img .date .num {
          display: block;
          font-family: "montserratextrabold";
          font-size: 36px;
          text-align: center;
          line-height: 1;
          padding-top: 5px; }
      .news__block .news__item .news__img .more {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        margin: 0;
        color: #fff;
        opacity: 0;
        z-index: 1;
        transition: 300ms; }
      .news__block .news__item .news__img:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: 300ms;
        opacity: 0; }
    .news__block .news__item .news__info {
      flex: 1 0 auto;
      border: 1px solid #ebf1fb;
      padding: 40px 30px;
      transition: 300ms; }
    .news__block .news__item h5 {
      color: #6c1b34;
      margin: 0 0 10px;
      transition: 300ms; }
    .news__block .news__item p {
      margin-bottom: 0; }
    .news__block .news__item:hover {
      color: #e2d1d6; }
      .news__block .news__item:hover h5 {
        color: #fff; }
      .news__block .news__item:hover .news__img:after {
        opacity: 1; }
      .news__block .news__item:hover .news__img .more {
        opacity: 1; }
      .news__block .news__item:hover .news__info {
        background-color: #6c1b34;
        border: 1px solid #6c1b34; }
  @media screen and (max-width: 1024px) {
    .news__block .news__item {
      width: calc(50% - 7.5px); }
      .news__block .news__item .news__img {
        height: 270px; }
        .news__block .news__item .news__img .date {
          width: 70px;
          height: 100px;
          left: 20px; }
          .news__block .news__item .news__img .date .num {
            font-size: 30px;
            padding-top: 0; }
      .news__block .news__item .news__info {
        padding: 30px 20px; } }
  @media screen and (max-width: 600px) {
    .news__block .news__item {
      width: 100%;
      margin-bottom: 25px; }
      .news__block .news__item:nth-last-of-type(2n) {
        margin-bottom: 25px; }
      .news__block .news__item:last-of-type {
        margin-bottom: 0 !important; }
      .news__block .news__item .news__info {
        padding: 25px 20px; } }
  @media screen and (max-width: 420px) {
    .news__block .news__item .news__img {
      height: 200px; }
      .news__block .news__item .news__img .date {
        height: 80px;
        left: 15px; }
        .news__block .news__item .news__img .date .num {
          font-size: 24px; } }

/* main news */
/* main content */
/* page content */
.page {
  padding-bottom: 80px; }
  .page .news1 .date, .page .news .date {
    color: #6c1b34; }
  .page .page_nav .active {
    color: #6c1b34; }
  .page #ask-form {
    max-width: 350px; }
  .page .write {
    color: #6c1b34;
    text-decoration: none; }
    .page .write:hover {
      text-decoration: underline; }
  .page .back_link {
    margin: 25px 0; }
  .page p a {
    color: #6c1b34;
    text-decoration: underline; }
    .page p a:hover {
      text-decoration: none; }
  .page .h2 a {
    font-size: 16px;
    color: #6c1b34; }
  @media screen and (max-width: 1440px) {
    .page {
      padding-bottom: 60px; } }
  @media screen and (max-width: 1280px) {
    .page {
      padding-bottom: 50px; } }
  @media screen and (max-width: 1024px) {
    .page {
      padding-bottom: 30px; }
      .page #photos-list td img {
        width: 100%;
        height: auto; } }
  @media screen and (max-width: 480px) {
    .page p img, .page p strong img, .page h2 img, .page h3 img, .page h4 img, .page h5 img, .page h6 img, .page p a img, .page strong img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      float: none !important;
      margin: 0 0 10px 0 !important; }
    .page #page-text p a img {
      width: auto !important;
      max-width: 100% !important; }
    .page #photos-list td {
      width: 50%;
      display: inline-block;
      vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-family: "montserratmedium";
  font-size: 16px;
  line-height: 1;
  margin: 30px 0 45px; }
  .breadcrumbs ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      padding: 3px 0px;
      color: #333333;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        text-decoration: none;
        color: #6c1b34; }
        .breadcrumbs ul li a:hover {
          color: #333333; }
      .breadcrumbs ul li:after {
        content: '\f105';
        font-family: 'fontAwesome';
        font-size: 18px;
        margin: -1px 12px 0px 12px;
        color: #8acfef;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  @media screen and (max-width: 1024px) {
    .breadcrumbs {
      font-size: 14px;
      margin: 15px 0 30px; }
      .breadcrumbs ul li:after {
        font-size: 16px;
        margin: -1px 8px 0; } }
  @media screen and (max-width: 768px) {
    .breadcrumbs {
      margin: 10px 0 15px; } }

/* breadcrumbs */
/* page services */
.services__block1 {
  height: 445px;
  display: flex;
  position: relative; }
  .services__block1:before {
    content: '';
    width: 50%;
    background: linear-gradient(to right, rgba(25, 23, 37, 0.5), rgba(25, 23, 37, 0));
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0; }
  .services__block1 > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .services__block1 .container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 15px 150px;
    z-index: 1; }
  .services__block1 .advantages__block {
    background-color: rgba(25, 23, 37, 0.7);
    width: 100%;
    min-height: 130px;
    color: #fff;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 15px;
    z-index: 1; }
    .services__block1 .advantages__block h5 {
      color: #fff; }
    .services__block1 .advantages__block .advantages__item {
      border: none !important; }
  .services__block1 h1 {
    font-size: 48px;
    color: #fff;
    margin: 0; }
  @media screen and (max-width: 1440px) {
    .services__block1 h1 {
      font-size: 36px; } }
  @media screen and (max-width: 1200px) {
    .services__block1 h1 {
      font-size: 30px; } }
  @media screen and (max-width: 1024px) {
    .services__block1 {
      height: 375px; }
      .services__block1 h1 {
        font-size: 26px; } }
  @media screen and (max-width: 768px) {
    .services__block1 {
      height: auto;
      flex-direction: column-reverse; }
      .services__block1:before {
        display: none; }
      .services__block1 h1 {
        font-size: 22px; }
        .services__block1 h1 br {
          display: none; }
      .services__block1 .advantages__block {
        position: relative;
        background-color: transparent;
        border: none; }
      .services__block1 .container {
        background-color: rgba(25, 23, 37, 0.7);
        padding: 25px 15px 15px;
        flex-direction: column; }
      .services__block1 .btn {
        margin: 0px auto 15px; }
      .services__block1 > img {
        position: relative;
        height: auto; } }
  @media screen and (max-width: 600px) {
    .services__block1 h1 {
      font-size: 20px; } }

.services__adv {
  display: flex;
  justify-content: space-between;
  margin: 50px -15px; }
  .services__adv .item {
    max-width: 25%;
    padding: 0px 15px 0; }
    .services__adv .item .icon {
      width: 50px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .services__adv .item .icon img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: left top; }
    .services__adv .item h5 {
      font-family: "montserratsemibold";
      margin: 15px 0; }
  @media screen and (max-width: 768px) {
    .services__adv {
      flex-wrap: wrap;
      margin: 30px 0; }
      .services__adv .item {
        width: 50%;
        max-width: 50%;
        padding-left: 0; } }
  @media screen and (max-width: 380px) {
    .services__adv .item {
      max-width: 100%;
      width: 100%; } }

.tariff {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 -15px; }
  .tariff .tariff__item {
    width: calc(33.33% - 30px);
    padding: 40px 20px 70px;
    text-align: center;
    position: relative;
    margin: 15px;
    border: 1px solid #ecf2fb; }
    .tariff .tariff__item .tariff__rooms {
      display: flex;
      justify-content: center; }
      .tariff .tariff__item .tariff__rooms .room {
        width: 50px;
        height: 50px;
        border: 2px solid #3cafe5;
        border-radius: 3px;
        margin: 0 2.5px; }
    .tariff .tariff__item ul {
      display: table;
      text-align: left;
      margin: 0 auto; }
      .tariff .tariff__item ul li {
        margin-bottom: 10px; }
    .tariff .tariff__item h5 {
      margin: 25px 0 20px; }
    .tariff .tariff__item .price {
      display: block;
      font-family: "montserratsemibold";
      font-size: 26px;
      color: #3cafe5;
      margin: 30px 0; }
      .tariff .tariff__item .price .tg {
        font-weight: bold; }
    .tariff .tariff__item .red-btn {
      display: flex;
      height: 70px;
      background-color: #6c1b34;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      line-height: 1; }
      .tariff .tariff__item .red-btn:hover {
        background-color: #3cafe5;
        color: #fff; }
  @media screen and (max-width: 1440px) {
    .tariff .tariff__item .price {
      font-size: 24px; } }
  @media screen and (max-width: 1280px) {
    .tariff {
      margin: 0 -7.5px; }
      .tariff .tariff__item {
        width: calc(33.33% - 15px);
        margin: 7.5px; } }
  @media screen and (max-width: 1024px) {
    .tariff .tariff__item {
      padding: 25px 15px 50px; }
      .tariff .tariff__item .tariff__rooms .room {
        width: 40px;
        height: 40px; }
      .tariff .tariff__item .red-btn {
        height: 50px; }
      .tariff .tariff__item .price {
        font-size: 20px;
        margin: 20px 0; } }
  @media screen and (max-width: 768px) {
    .tariff {
      justify-content: center; }
      .tariff .tariff__item {
        width: calc(50% - 15px); } }
  @media screen and (max-width: 480px) {
    .tariff {
      margin: 0; }
      .tariff .tariff__item {
        width: 100%;
        margin: 7.5px 0; } }

.page .shares__item:nth-last-of-type(-n+2) {
  margin-bottom: 20px; }

/* page services */
/* page contacts */
.map {
  position: relative;
  padding: 95px 0; }
  .map ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .map > ymaps {
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; }
  .map .ymaps-2-1-76-map {
    height: 100% !important; }
  .map .map__block {
    width: 460px;
    background-color: #fff;
    padding: 20px 40px 50px;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); }
    .map .map__block h5 {
      margin: 40px 0 20px; }
    .map .map__block ul li {
      margin-bottom: 12px; }
  .map .container {
    position: relative;
    z-index: 1; }
  @media screen and (max-width: 1440px) {
    .map {
      padding: 50px 0; }
      .map .map__block {
        padding-bottom: 40px; }
        .map .map__block h5 {
          margin-top: 30px; } }
  @media screen and (max-width: 1200px) {
    .map .form__block .col {
      width: calc(50% - 7.5px); } }
  @media screen and (max-width: 1024px) {
    .map {
      padding: 30px 0; }
      .map .map__block {
        width: 375px;
        padding: 10px 20px 30px; } }
  @media screen and (max-width: 768px) {
    .map {
      padding: 0; }
      .map > ymaps {
        height: 250px !important;
        position: relative; }
      .map .map__block {
        width: 100%;
        padding: 0;
        box-shadow: none;
        margin-bottom: 15px; } }

.form-group {
  position: relative;
  cursor: text; }
  .form-group .placeholder {
    height: 50px;
    font-family: "montserratregular";
    color: #999999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    line-height: 1;
    padding: 13px 30px;
    display: block;
    cursor: text !important; }
    .form-group .placeholder .name {
      cursor: text !important; }
    .form-group .placeholder .star {
      color: #49b4e7;
      font-size: 20px;
      margin-left: 5px;
      cursor: text; }
  @media screen and (max-width: 1440px) {
    .form-group .placeholder {
      height: 44px;
      padding: 12px 20px; } }
  @media screen and (max-width: 1024px) {
    .form-group .placeholder {
      height: 38px;
      font-size: 14px;
      padding: 10px 15px; }
      .form-group .placeholder .star {
        font-size: 18px; } }
  @media screen and (max-width: 600px) {
    .form-group .placeholder {
      padding-top: 11px; } }

.cts h3 {
  font-family: "montserratbold"; }

.cts form p {
  margin-bottom: 30px; }

.cts form .form__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .cts form .form__block .col {
    width: calc(50% - 15px);
    justify-content: space-between; }

.cts form input[type="text"],
.cts form input[type="email"] {
  margin-bottom: 20px; }

.cts form textarea {
  height: 191px; }

.cts form .g-recaptcha {
  float: right; }

@media screen and (max-width: 1440px) {
  .cts form textarea {
    height: 178px; } }

@media screen and (max-width: 1200px) {
  .cts form .form__block .col {
    width: calc(50% - 7.5px); } }

@media screen and (max-width: 1024px) {
  .cts form p {
    margin-bottom: 20px; }
  .cts form textarea {
    height: 150px; }
  .cts form input[type="text"],
  .cts form input[type="email"] {
    margin-bottom: 15px; } }

@media screen and (max-width: 660px) {
  .cts form .form__block .col {
    width: 100%; }
  .cts form textarea {
    height: 100px; }
  .cts form .g-recaptcha {
    float: none; }
  .cts form .btn {
    float: right;
    margin-top: -45px; } }

@media screen and (max-width: 460px) {
  .cts form .g-recaptcha {
    display: table;
    float: none;
    margin: 0 auto; }
  .cts form .btn {
    float: none;
    margin: 15px auto 0; } }

@media screen and (max-width: 340px) {
  .cts form .g-recaptcha {
    display: block;
    width: 280px; }
    .cts form .g-recaptcha > div {
      transform: scale(0.9);
      transform-origin: 0 0; }
  .cts form .btn {
    margin-top: 10px; } }

/* page contacts */
/* page pagination */
.pagination {
  margin-top: 30px; }
  .pagination ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .pagination ul li {
      margin: 0 5px 10px; }
      .pagination ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "montserratsemibold";
        font-size: 18px;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 3px;
        border: 1px solid #ebf1fb; }
        .pagination ul li a:hover {
          border: 1px solid #6c1b34;
          color: #6c1b34; }
      .pagination ul li.active a {
        border: 1px solid #6c1b34;
        color: #6c1b34; }
      .pagination ul li.prev a:after, .pagination ul li.next a:after {
        content: '\f101';
        font-family: 'fontAwesome';
        font-size: 20px; }
      .pagination ul li.prev a:after {
        content: '\f100'; }
  @media screen and (max-width: 1024px) {
    .pagination ul li a {
      width: 30px;
      height: 30px;
      font-size: 14px; }
    .pagination ul li.prev a:after, .pagination ul li.next a:after {
      font-size: 16px; } }

/* page pagination */
/* modal */
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .modal .modal__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal__block {
    width: 420px;
    background-color: #fff;
    position: absolute;
    padding: 20px;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%); }
    .modal .modal__block h4 {
      font-size: 18px;
      text-align: center;
      padding: 0 10px;
      margin: 0; }
  .modal form {
    display: block;
    padding-bottom: 130px;
    margin-top: 15px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 50px;
      width: 100%;
      background-color: #fff;
      font-family: "montserratregular";
      font-size: 16px;
      margin-bottom: 15px;
      box-shadow: none;
      border: 1px solid #d3dded;
      padding: 15px 20px 14px;
      border-radius: 3px; }
      .modal form textarea::placeholder,
      .modal form input[type="text"]::placeholder,
      .modal form input[type="email"]::placeholder,
      .modal form input[type="search"]::placeholder,
      .modal form input[type="password"]::placeholder {
        color: #999999; }
    .modal form .palceholder {
      padding-left: 20px;
      padding-right: 20px; }
    .modal form .g-recaptcha {
      float: right; }
    .modal form .btn {
      float: right;
      margin: 15px 0 0 0; }
  @media screen and (max-width: 1440px) {
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 45px;
      padding: 13px 20px 12px; } }
  @media screen and (max-width: 1200px) {
    .modal .modal__block h4 {
      font-size: 18px; } }
  @media screen and (max-width: 1024px) {
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px 9px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 768px) {
    .modal .modal__block h4 {
      font-size: 16px; } }
  @media screen and (max-width: 500px) {
    .modal .modal__block {
      width: auto;
      left: 10px;
      right: 10px; } }
  @media screen and (max-width: 380px) {
    .modal form {
      padding-bottom: 120px; }
      .modal form .g-recaptcha {
        transform: scale(0.85);
        transform-origin: 100% 50%; }
      .modal form .btn {
        margin-top: 10px; } }

.call--btn {
  display: none;
  position: fixed;
  bottom: 100px;
  right: 40px;
  background-color: #3caee4;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 23px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  outline: none !important;
  z-index: 2; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 35px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #3caee4;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s; }
  @media screen and (max-width: 1440px) {
    .call--btn {
      margin-right: 0;
      right: 35px;
      bottom: 75px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 15px;
      right: 15px;
      bottom: 80px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      padding: 0;
      bottom: 30px; }
      .call--btn:before, .call--btn:after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        box-sizing: border-box; }
      .call--btn i {
        font-size: 20px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* modal */
/* page content */
/* footer */
footer {
  background-color: #272330;
  font-size: 16px;
  color: #a4a2a8;
  position: relative; }
  footer:after {
    content: '';
    width: calc(50% - 150px);
    background-color: #1b1725;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0; }
  footer h2, footer h3, footer h4, footer h5 {
    font-family: "montserratbold";
    font-size: 18px;
    color: #ffffff;
    margin: 0 0 20px; }
  footer h6 {
    font-family: "montserratregular";
    font-size: 14px;
    color: #a4a2a8;
    margin: 0; }
  footer a {
    color: #a4a2a8; }
    footer a:focus {
      color: #a4a2a8; }
    footer a:hover {
      color: #fff; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    footer ul li {
      margin: 0 0 10px; }
      footer ul li.active a {
        color: #fff; }
  footer .container {
    display: flex;
    justify-content: space-between; }
  footer > .container {
    padding: 85px 15px 80px;
    position: relative;
    z-index: 1; }
  footer .footer__item {
    max-width: 33.33%;
    padding-right: 10px; }
    footer .footer__item.f--ser {
      max-width: 400px; }
    footer .footer__item.f--cts {
      padding-right: 0; }
      footer .footer__item.f--cts ul li {
        margin-bottom: 12px; }
  footer .f--socials {
    margin-top: 50px; }
  footer .btn {
    margin-top: 60px; }
  footer .socials li a {
    background-color: #47444f;
    color: #a3a2a7; }
  footer .down {
    font-size: 14px;
    position: relative;
    z-index: 1;
    border-top: 1px solid #322f3b; }
    footer .down .container {
      height: 60px;
      align-items: center;
      justify-content: space-between; }
    footer .down .artmedia {
      display: flex;
      align-items: center;
      text-decoration: none; }
      footer .down .artmedia img {
        margin-left: 10px; }
  @media screen and (max-width: 1440px) {
    footer > .container {
      padding: 60px 15px 60px; } }
  @media screen and (max-width: 1280px) {
    footer > .container {
      padding: 50px 15px 40px; } }
  @media screen and (max-width: 1024px) {
    footer > .container {
      padding: 30px 15px 20px; }
    footer:after {
      display: none; } }
  @media screen and (max-width: 768px) {
    footer {
      font-size: 14px; }
      footer h2, footer h3, footer h4 {
        font-size: 16px;
        margin-bottom: 15px; }
      footer .circle--list li {
        padding-left: 15px;
        margin-bottom: 10px; }
        footer .circle--list li:after {
          top: 5px; }
      footer .f--socials {
        margin-top: 30px; }
      footer .btn {
        margin-top: 30px; }
      footer > .container {
        flex-direction: column; }
      footer .footer__item {
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        margin-bottom: 20px; }
        footer .footer__item.f--cts {
          margin-bottom: 10px; }
        footer .footer__item.f--ser {
          max-width: 100%; }
      footer .f--socials {
        margin-top: 20px; } }
  @media screen and (max-width: 600px) {
    footer .down {
      text-align: center; }
      footer .down .container {
        height: auto;
        flex-direction: column;
        padding: 15px; }
      footer .down .artmedia {
        margin-top: 10px; }
    footer .footer__item.f--ser {
      display: none; } }

/* footer */
