/* footer */
footer {
    background-color: #272330;
    font-size: 16px;
    color: #a4a2a8;
    position: relative;
    &:after {
        content: '';
        width: calc(50% - 150px);
        background-color: #1b1725;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
    h2,h3,h4,h5 {
        font-family: $bold;
        font-size: 18px;
        color: #ffffff;
        margin: 0 0 20px;
    }
    h6 {
        font-family: $regular;
        font-size: 14px;
        color: #a4a2a8;
        margin: 0;
    }
    a {
        color: #a4a2a8;
        &:focus {
            color: #a4a2a8;
        }
        &:hover {
            color: #fff;
        }
    }
    ul {
        @include ul-default;
        li {
            margin: 0 0 10px;
            &.active a {
                color: #fff;
            }
        }
    }
    .container {
        display: flex;
        justify-content: space-between;
    }
    > .container {
        padding: 85px 15px 80px;
        position: relative;
        z-index: 1;
    }
    .footer__item {
        max-width: 33.33%;
        padding-right: 10px;
        &.f--ser {
            max-width: 400px;
        }
        &.f--cts {
            padding-right: 0;
            ul li {
                margin-bottom: 12px;
            }
        }
    }
    .f--socials {
        margin-top: 50px;
    }
    .btn {
        margin-top: 60px;
    }
    .socials li a {
        background-color: #47444f;
        color: #a3a2a7;
    }
    .down {
        font-size: 14px;
        position: relative;
        z-index: 1;
        border-top: 1px solid #322f3b;
        .container {
            height: 60px;
            align-items: center;
            justify-content: space-between;
        }
        .artmedia {
            display: flex;
            align-items: center;
            text-decoration: none;
            img {
                margin-left: 10px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        > .container {
            padding: 60px 15px 60px;
        }
    }
    @media screen and (max-width: 1280px) {
        > .container {
            padding: 50px 15px 40px;
        }
    }
    @media screen and (max-width: 1024px) {
        > .container {
            padding: 30px 15px 20px;
        }
        &:after {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        h2,h3,h4 {
            font-size: 16px;
            margin-bottom: 15px;
        }
        .circle--list {
            li {
                padding-left: 15px;
                margin-bottom: 10px;
                &:after {
                    top: 5px;
                }
            }
        }
        .f--socials {
            margin-top: 30px;
        }
        .btn {
            margin-top: 30px;
        }
        > .container {
            flex-direction: column;
        }
        .footer__item {
            width: 100%;
            max-width: 100%;
            padding-right: 0;
            margin-bottom: 20px;
            &.f--cts {
                margin-bottom: 10px;
            }
            &.f--ser {
                max-width: 100%;
            }
        }
        .f--socials {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 600px) {
        .down {
            text-align: center;
            .container {
                height: auto;
                flex-direction: column;
                padding: 15px;
            }
            .artmedia {
                margin-top: 10px;
            }
        }
        .footer__item {
            &.f--ser {
                display: none;
            }
        }
    }
}
/* footer */