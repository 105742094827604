/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider--block {
	position: relative;
	overflow: hidden;
}
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		height: calc(100vh - 115px);
		outline: none;
		&:after {
			content: '';
			background: linear-gradient(to bottom, rgba(27,23,37,0), rgba(27,23,37,0.8));
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		.slider__block {
			width: 600px;
			font-family: $medium;
			font-size: 18px;
			position: absolute;
			left: 50%;
			right: 0;
			top: 50%;
			color: #fff;
			transform: translate(-570px,-50%);
			z-index: 1;
			.slider__title {
				display: block;
				font-family: $bold;
				font-size: 48px;
				line-height: 1.2;
				margin-bottom: 20px;
			}
			.btn {
				margin-top: 45px;
			}
		}
	}
	&.arrow {
		.slick-arrow {
			width: 44px;
			height: 44px;
			background-color: rgba(255,255,255,0.3);
			border-radius: 100%;
			top: auto;
			bottom: 36px;
			transform: translate(0);
			&:after {
				color: #fff;
			}
			&:hover {
				background-color: #fff;
				&:after {
					color: #6c1b34;
				}
			}
			&.slick-prev {
				left: 90px;
			}
			&.slick-next {
				right: 90px;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		&.arrow {
			.slick-arrow {
				&.slick-prev {
					left: 40px;
				}
				&.slick-next {
					right: 40px;
				}
			}
		}
		.slick-slide {
			.slider__block {
				top: 45%;
				.slider__title {
					font-size: 40px;
				}
				.btn {
					margin-top: 25px;
				}
			}
		}
	}
	@media screen and (max-width: 1366px) {
		&.arrow {
			.slick-arrow {
				bottom: 22px;
				&.slick-prev {
					left: 25px;
				}
				&.slick-next {
					right: 25px;
				}
			}
		}
		.slick-slide {
			.slider__block {
				left: 100px;
				transform: translateY(-50%);
				.slider__title {
					font-size: 32px;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-slide {
			.slider__block {
				.slider__title {
					font-size: 24px;
				}
			}
		}
		&.arrow {
			.slick-arrow {
				width: 40px;
				height: 40px;
				bottom: 27px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			height: 350px;
			.slider__block {
				width: auto;
				left: 75px;
				right: 75px;
				font-size: 16px;
				.slider__title {
					font-size: 22px;
					margin-bottom: 15px;
				}
			}
		}
		&.arrow {
			.slick-arrow {
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
				&.slick-prev {
					left: 15px;
				}
				&.slick-next {
					right: 15px;
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.slick-slide {
			height: 300px;
			.slider__block {
				left: 60px;
				right: 60px;
				.slider__title {
					font-size: 18px;
				}
				.btn {
					margin-top: 20px;
				}
			}
		}
		&.arrow {
			.slick-arrow {
				width: 35px;
				height: 35px;
				&:after {
					font-size: 20px;
					line-height: 20px;
				}
				&.slick-prev {
					left: 10px;
				}
				&.slick-next {
					right: 10px;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			height: auto;
			> img {
				height: 200px;
			}
			.slider__block {
				text-align: center;
				background-color: #6c1b34;
				padding: 20px 15px;
				position: relative;
				top: 0;
				left: 0;
				right: 0;
				transform: translateY(0);
			}
			.btn {
				margin-left: auto;
				margin-right: auto;
			}
		}
		&.arrow {
			.slick-arrow {
				top: 95px;
			}
		}
	}
	@media screen and (max-width: 380px) {
		.slick-slide {
			> img {
				height: 150px;
			}
		}
		&.arrow {
			.slick-arrow {
				top: 80px;
			}
		}
	}
}
.slider-progress {
	width: 1180px;
	background-color: rgba(255,255,255,0.3);
	position: absolute;
	bottom: 115px;
	left: 0;
	right: 0;
	margin: 0 auto;
	.progress {
		width: 0%;
		height: 2px;
		background: #3cafe5;
	}
	@media screen and (max-width: 1366px) {
		width: auto;
		left: 100px;
		right: 100px;
		bottom: 95px;
	}
	@media screen and (max-width: 1024px) {
		bottom: 90px;
		left: 75px;
		right: 75px;
	}
	@media screen and (max-width: 768px) {
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.slider__nav {
	width: 1180px;
	position: absolute;
	bottom: 38px;
	left: 0;
	right: 0;
	margin: 0 auto;
	.slick-track {
		display: flex;
	}
	.slick-slide {
		outline: none;
	}
	.slider__item {
		display: flex;
		height: 40px;
		font-family: $boldS;
		font-weight: normal;
		color: #fff;
		align-items: flex-start;
		opacity: 0.5;
		padding: 0 15px;
		border-right: 2px solid rgba(255,255,255,0.3);
		cursor: pointer;
		transition: 300ms;
		&:last-of-type {
			border-right: none;
		}
		&.slick-current, &:hover {
			opacity: 1;
		}
	}
	@media screen and (max-width: 1366px) {
		width: auto;
		left: 100px;
		right: 100px;
		bottom: 25px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 14px;
		left: 75px;
		right: 75px;
	}
	@media screen and (max-width: 768px) {
		display: none;
	}
}
.arrow {
	.slick-arrow {
		width: 20px;
		height: 20px;
		z-index: 1;
		transition: 300ms;
		&:before {
			display: none;
		}
		&:after {
			content: '\f104';
			font-family: 'fontAwesome';
			font-size: 28px;
			color: #6c1b34;
			transition: 300ms;
			line-height: 24px;
		}
		&.slick-prev {
			left: 0;
		}
		&.slick-next {
			right: 0;
			&:after {
				transform: rotate(-180deg);
			}
		}
		&:hover {
			opacity: 0.7;
		}
	}
	&.arrow-right {
		.slick-arrow {
			top: -70px;
			transform: translate(0);
			&.slick-prev {
				right: 50%;
				left: auto;
				margin-right: -530px;
				&:after {
					margin-top: -2px;
				}
			}
			&.slick-next {
				right: 50%;
				margin-right: -570px;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		&.arrow-right {
			.slick-arrow {
				top: -58px;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		&.arrow-right {
			.slick-arrow {
				top: -55px;
				&.slick-next {
					right: 15px;
					margin: 0;
				}
				&.slick-prev{
					right: 50px;
					margin: 0;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		&.arrow-right {
			.slick-arrow {
				top: -40px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		&.arrow-right {
			.slick-arrow {
				top: -38px;
				&.slick-prev {
					right: 45px;
				}
			}
		}
	}
		
}
/* main slider */

/* main advantages */
.advantages {
	h2 {
		text-align: center;
	}
	@media screen and (max-width: 1024px) {
		h1 {
			margin-bottom: 20px;
		}
	}
}
.advantages__block {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #d1e7f8;
	&.up {
		padding-bottom: 30px;
		margin: 0 0 30px;
	}
	@media screen and (max-width: 1024px) {
		&.up {
			padding-bottom: 20px;
			margin-bottom: 20px;
		}
	}
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		&.up {
			padding-bottom: 0px;
			margin: 0;
			border: none;
		}
	}
}
.advantages__item {
	display: flex;
	max-width: 35%;
	min-height: 76px;
	align-items: center;
	position: relative;
	padding-left: 115px;
	padding-right: 15px;
	&.center {
		padding-right: 30px;
		border-left: 2px solid #d1e7f8;
		border-right: 2px solid #d1e7f8;
	}
	.icon {
		position: absolute;
		left: 28px;
		top: 50%;
		transform: translateY(-50%);
	}
	h5 {
		font-family: $boldS;
		margin: 0;
	}
	@media screen and (max-width: 1024px) {
		min-height: 65px;
		padding-left: 90px;
		.icon {
			width: 50px;
			height: 60px;
			left: 20px;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center center;
			}
		}
	}
	@media screen and (max-width: 768px) {
		flex-direction: column;
		max-width: 100%;
		width: 100%;
		min-height: 60px;
		text-align: center;
		padding-right: 0;
		border: none;
		padding: 0;
		margin-bottom: 15px;
		&.center {
			border: none;
			padding-right: 0;
		}
		.icon {
			position: relative;
			top: 0;
			left: 0;
			margin: 0 auto;
			transform: translate(0);
			margin-bottom: 10px;
		}
	}
}
.advantages__down {
	display: flex;
	padding: 0 90px;
	.col {
		width: 50%;
		padding-right: 50px;
		border-right: 2px solid #d1e7f8;
		&:last-of-type {
			border-right: none;
			padding-right: 0;
			padding-left: 50px;
		}
	}
	.advantages__item {
		max-width: 100%;
		width: 100%;
		padding-right: 0;
		margin: 10px 0;
	}
	@media screen and (max-width: 1024px) {
		padding: 0;
		.col {
			padding-right: 20px;
			&:last-of-type {
				padding-left: 20px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		.col {
			width: 100%;
			padding: 0!important;
			border: none;
		}
	}
}
/* main advantages */

/* main services */
.services {
	h2 {
		text-align: center;
	}
}
.services__block {
	padding: 0 80px;
	@media screen and (max-width: 1024px) {
		padding: 0 30px;
	}
	@media screen and (max-width: 768px) {
		padding: 0 15px;
	}
}
.services__nav {
	width: 100%;
	border: 2px solid #ebf1fb;
	border-radius: 3px;
	margin-bottom: 40px;
	.services__item {
		height: 160px;
		font-family: $medium;
		color: #1b1725;
		text-align: center;
		position: relative;
		padding: 25px 15px;
		border-right: 2px solid #ebf1fb;
		transition: 300ms;
		overflow: hidden;
		cursor: pointer;
		img {
			max-width: 80px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			transition: 300ms;
			&.h {
				opacity: 0;
			}
		}
		&:hover, &.slick-current {
			background-color: #6c1b34;
			color: #fff;
			img {
				&.v {
					opacity: 0;
				}
				&.h {
					opacity: 1;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		margin-bottom: 20px;
		.services__item {
			height: 140px;
			font-size: 14px;
			padding: 20px 15px;
		}
	}
	@media screen and (max-width: 768px) {
		.services__item {
			height: 120px;
			img {
				max-height: 50px;
			}
		}
	}
}
.services__for {
	.btn {
		@include inline-block;
		margin: 35px 20px 0 0;
	}
	.slick-slide {
		outline: none;
	}
	.services__img {
		img {
			display: block;
			width: 100%;
		}
	}
	h3 {
		font-family: $bold;
		font-size: 24px;
		margin: 40px 0 20px;
	}
	&.arrow {
		.slick-arrow {
			top: -125px;
			&.slick-prev {
				left: -30px;
			}
			&.slick-next {
				right: -30px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		h3 {
			font-size: 20px;
			margin: 30px 0 20px;
		}
		.btn {
			margin: 15px 15px 0 0;
		}
		&.arrow {
			.slick-arrow {
				top: -98px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		&.arrow {
			.slick-arrow {
				top: -88px;
				&.slick-prev {
					left: -25px;
				}
				&.slick-next {
					right: -25px;
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		h3 {
			font-size: 16px;
			margin: 20px 0 15px;
		}
	}
}
/* main services */

/* main car park */
.bg {
	position: relative;
	color: #fff;
	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
	h2, h1 {
		color: #fff;
		a {
			color: #fff;
		}
	}
	.arrow {
		.slick-arrow {
			&:after {
				color: #fff;
			}
		}
	}
}
.car__nav {
	width: 600px;
	transform: translateX(100px);
	margin: -67px auto 75px;
	.slick-slide {
		width: auto!important;
		padding-right: 20px;
		outline: none;
		padding-bottom: 15px;
		margin-right: 35px;
		color: #aa9097;
		position: relative;
		cursor: pointer;
		line-height: 1;
		&:after {
			content: '';
			height: 2px;
			background-color: #3cafe5;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 100%;
			transition: 300ms;
		}
		&.slick-current {
			color: #fff;
			&:after {
				right: 0;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		margin-top: -53px;
	}
	@media screen and (max-width: 1200px) {
		margin-top: -50px;
		.slick-slide {
			padding: 0 15px 10px 0;
			margin-right: 20px;
		}
	}
	@media screen and (max-width: 1024px) {
		margin-top: -35px;
		.slick-slide {
			padding-right: 10px;
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 768px) {
		display: none;
		
	}
}
.car__for {
	.slick-list {
		padding: 0 25%!important;
	}
	.slick-slide {
		outline: none;
		margin: 0 100px;
		transition: 300ms;
		opacity: 0.5;
		img {
			height: 290px;
			max-width: 100%;
			object-fit: contain;
			object-position: center bottom;
			margin: 0 auto;
		}
		&.slick-center {
			opacity: 1;
			.car-slider__down {
				opacity: 1;
			}
		}
	}
	.car-slider__down {
		border-top: 2px solid #85485c;
		margin-top: 50px;
		transition: 300ms;
		opacity: 0;
		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;	
			padding: 30px 0 15px;
		}
		h3, h6 {
			color: #fff;
			margin: 0;
		}
	}
	&.arrow.arrow-right {
		.slick-arrow {
			top: -110px;
		}
	}
	@media screen and (max-width: 1680px) {
		.slick-list {
			padding: 0 22%!important;
		}
		.slick-slide {
			margin: 0 75px;
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-list {
			padding: 0 18%!important;
		}
		.slick-slide {
			margin: 0 50px;
		}
	}
	@media screen and (max-width: 1200px) {
		.slick-list {
			padding: 0 15px!important;
		}
		.slick-slide {
			margin: 0;
		}
		&.arrow.arrow-right {
			.slick-arrow {
				top: -107px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-slide {
			img {
				height: 200px;
			}
		}
		.car-slider__down {
			margin-top: 30px;
			.row {
				padding: 15px 0;
			}
		}
		&.arrow {
			&.arrow-right {
				.slick-arrow {
					top: -105px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		&.arrow {
			&.arrow-right {
				.slick-arrow {
					top: -38px;
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.slick-slide {
			img {
				height: 150px;
			}
		}
		.car-slider__down {
			margin-top: 20px;
			h3 {
				font-size: 16px;
			}
			h6 {
				font-size: 14px;
			}
		}
	}
	@media screen and (max-width: 540px) {
		.car-slider__down {
			.row {
				flex-direction: column;
			}
			h3 {
				margin: 0 0 10px;
			}
			h6 {
				margin-bottom: 10px;
			}
			.btn {
				margin-top: 10px;
			}
		}
	}
}
.car-park {
	padding-bottom: 80px;
	.container {
		padding-bottom: 0;
	}
	@media screen and (max-width: 1440px) {
		padding-bottom: 60px;
	}
	@media screen and (max-width: 1200px) {
		padding-bottom: 50px;
	}
	@media screen and (max-width: 1024px) {
		padding-bottom: 30px;
	}
}

/* main car park */

/* main shares */
.shares {
	.more {
		float: right;
	}
}
.shares__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.shares__item {
		width: calc(50% - 15px);
		margin-bottom: 60px;
		&:nth-last-of-type(-n+2) {
			margin-bottom: 0px;
		}
		.shares__img {
			display: flex;
			background: url(../img/img-27.jpg) no-repeat center center;
			background-size: cover;
			height: 310px;
			justify-content: flex-end;
			align-items: flex-end;
			z-index: 1;
			margin-bottom: 25px;
			> img {
				position: relative;
				width: auto;
				height: auto;
				max-height: 100%;
				max-width: 100%;
				object-fit: contain;
				object-position: right bottom;
			}
			.shares__stiker {
				position: absolute;
				left: 30px;
				top: 35px;
				color: #ffffff;
				line-height: 1;
				h3 {
					font-family: $boldS;
					font-size: 18px;
					color: #fff;
					margin: 10px 0;
				}
				h2 {
					color: #3cafe5;
					text-transform: uppercase;	
					margin: 10px 0;
				}
			}
		}
		p {
			a {
				font-family: $boldS;
				color: #6c1b34;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
		.btn {
			margin-top: 25px;
		}
	}
	@media screen and (max-width: 1024px) {
		.shares__item {
			width: calc(50% - 7.5px);
			margin-bottom: 30px;
			.shares__img {
				height: 275px;
				> img {
					max-height: 80%;
				}
				.shares__stiker {
					left: 15px;
					top: 15px;
					h2 {
						font-size: 20px;
					}
					h3 {
						font-size: 16px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.shares__item {
			.shares__img {
				height: 200px;
				> img {
					max-height: 70%;
				}
				.shares__stiker {
					top: 10px;
					h2 {
						font-size: 18px;
					}
					h3 {
						font-size: 14px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.shares__item {
			width: 100%;
			margin-bottom: 25px;
			&:nth-last-of-type(2n) {
				margin-bottom: 25px;
			}
			&:last-of-type {
				margin-bottom: 0!important;
			}
			.shares__img {
				height: 250px;
				margin-bottom: 20px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.shares__item {
			.shares__img {
				height: 200px;
			}
			p a {
				display: table;
				margin-top: 5px;
			}
		}
	}
}
.more {
	font-family: $boldS;
	font-size: 14px;
	color: #6c1b34;
	text-transform: uppercase;
	position: relative;
	padding-right: 20px;
	margin-top: -65px;
	&:after {
		content: '\f101';
		font-family: 'fontAwesome';
		font-size: 18px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
	@media screen and (max-width: 1440px) {
		margin-top: -55px;
	}
	@media screen and (max-width: 1024px) {
		margin-top: -36px;
	}
	@media screen and (max-width: 768px) {
		padding-right: 15px;
		font-size: 12px;
		margin-top: -32px;
		&:after {
			font-size: 16px;
			top: 45%;
		}
	}
}
.page {
	.reviews__item {
		border: 2px solid #ebf1fb;
		.reviews__up {
			border-bottom: 2px solid #ebf1fb;
		}
	}
}
/* main sheres */

/* main partners */
.partners {
	h2 {
		padding-right: 50px;
	}
}
.partners__slider {
	.slick-list {
		padding: 0 75px!important;
	}
	.slick-slide {
		display: flex;
		height: 120px;
		align-items: center;
		justify-content: center;
		border: 1px solid #ebf1fb;
		padding: 10px;
		margin: 0 15px;
		transition: 300ms;
		opacity: 0.5;
		img {
			max-width: 100%;
			max-height: 100%;
			object-position: center center;
			object-fit: contain;
		}
		&.slick-active + .slick-active {
			opacity: 1;
		}
		&.slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active{
			opacity: 0.5;
		}
	}
	@media screen and (max-width: 1680px) {
		.slick-list {
			padding: 0 15%!important;
		}
		.slick-slide {
			&.slick-active {
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-list {
			padding: 0 9%!important;
		}
	}
	@media screen and (max-width: 1366px) {
		.slick-list {
			padding: 0 6%!important;
		}
	}
	@media screen and (max-width: 1200px) {
		.slick-list {
			padding: 0 7.5px!important;
		}
		.slick-slide {
			margin: 0 7.5px;
			&.slick-active + .slick-active + .slick-active + .slick-active + .slick-active + .slick-active{
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-slide {
			opacity: 1;
			height: 100px;
		}
	}
}
/* main partners */

/* main reviews */
.reviews {
	h2 {
		padding-right: 50px;
	}
}
.reviews__slider {
	.slick-list {
		padding: 0 19%!important;
	}
	.slick-track {
		display: flex;
	}
	.slick-slide {
		height: auto;
		opacity: 0.5;
		outline: none;
		&.slick-active {
			opacity: 1;
		}
		&.slick-active + .slick-slide + .slick-slide {
			opacity: 0.5;
		}
	}
	@media screen and (max-width: 1680px) {
		.slick-list {
			padding: 0 15%!important;
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-list {
			padding: 0 9%!important;
		}
	}
	@media screen and (max-width: 1366px) {
		.slick-list {
			padding: 0 6%!important;
		}
	}
	@media screen and (max-width: 1200px) {
		.slick-list {
			padding: 0 7.5px!important;
		}
		.slick-slide {
			opacity: 1;
			margin: 0 7.5px;
		}
	}
}
.reviews__item {
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	position: relative;
	margin: 0 15px;
	.reviews__up {
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ebf1fb;
		padding: 20px 25px;
		.name {
			font-family: $bold;
		}
	}
	.reviews--txt {
		max-height: 155px;
		overflow: hidden;
		position: relative;
		&:after {
			content: '';
			height: 40px;
			position: absolute;
			top: calc(100% - 40px);
			left: 0;
			right: 0;
			background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
		}
	}
	.reviews__down {
		flex: 1 0 auto;
		padding: 25px 25px 55px;
	}
	.more {
		display: table;
		position: absolute;
		bottom: 25px;
		left: 25px;
		margin: 0;
	}
	@media screen and (max-width: 480px) {
		.reviews__up {
			height: auto;
			flex-direction: column;
			.name {
				margin-bottom: 5px;
			}
		}
	}
}
/* main reviews */

/* main news */
.news__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.news__item {
		display: flex;
		flex-direction: column;
		width: calc(50% - 15px);
		margin-bottom: 30px;
		transition: 0ms;
		&:nth-last-of-type(-n+2) {
			margin-bottom: 0;
		}
		.news__img {
			height: 330px;
			position: relative;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
			.date {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 77px;
				height: 120px;
				background-color: #3cafe5;
				font-family: $boldS;
				font-size: 14px;
				color: #fff;
				text-align: center;
				position: absolute;
				top: 0;
				left: 30px;
				z-index: 1;
				.num {
					display: block;
					font-family: $boldE;
					font-size: 36px;
					text-align: center;
					line-height: 1;
					padding-top: 5px;
				}
			}
			.more {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
				margin: 0;
				color: #fff;
				opacity: 0;
				z-index: 1;
				transition: 300ms;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0,0,0,0.5);
				transition: 300ms;
				opacity: 0;
			}
		}
		.news__info {
			flex: 1 0 auto;
			border: 1px solid #ebf1fb;
			padding: 40px 30px;
			transition: 300ms;
		}
		h5 {
			color: #6c1b34;
			margin: 0 0 10px;
			transition: 300ms;
		}
		p {
			margin-bottom: 0;
		}
		&:hover {
			color: #e2d1d6;
			h5 {
				color: #fff;
			}
			.news__img {
				&:after {
					opacity: 1;
				}
				.more {
					opacity: 1;
				}
			}
			.news__info {
				background-color: #6c1b34;
				border: 1px solid #6c1b34;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.news__item {
			width: calc(50% - 7.5px);
			.news__img {
				height: 270px;
				.date {
					width: 70px;
					height: 100px;
					left: 20px;
					.num {
						font-size: 30px;
						padding-top: 0;
					}
				}
			}
			.news__info {
				padding: 30px 20px;
			}
		}
	}
	@media screen and (max-width: 600px) {
		.news__item {
			width: 100%;
			margin-bottom: 25px;
			&:nth-last-of-type(2n) {
				margin-bottom: 25px;
			}
			&:last-of-type {
				margin-bottom: 0!important;
			}
			.news__info {
				padding: 25px 20px;
			}
		}
	}	
	@media screen and (max-width: 420px) {
		.news__item {
			.news__img {
				height: 200px;
				.date {
					height: 80px;
					left: 15px;
					.num {
						font-size: 24px;
					}
				}
			}
		}
	}
}
/* main news */

/* main content */