/* page content */
.page {
    padding-bottom: 80px;
	.news1, .news {
		.date {
			color: #6c1b34;
		}
	}
	.page_nav {
		.active {
			color: #6c1b34;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #6c1b34;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #6c1b34;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .h2 a {
        font-size: 16px;
        color: #6c1b34;
    }
    @media screen and (max-width: 1440px) {
        padding-bottom: 60px;
    }
    @media screen and (max-width: 1280px) {
        padding-bottom: 50px;
    }
	@media screen and (max-width: 1024px) {
        padding-bottom: 30px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img, h2 img, h3 img, h4 img, h5 img, h6 img, p a img, strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
        #page-text p a img {
            width: auto!important;
            max-width: 100%!important;
        }
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    font-family: $medium;
    font-size: 16px;
    line-height: 1;
    margin: 30px 0 45px;
	ul {
        display: flex;
        flex-wrap: wrap;
		@include ul-default;
		li {
			padding: 3px 0px;
			color: #333333;
			@include inline-block;
			a {
                text-decoration: none;
                color: #6c1b34;
                &:hover {
                    color: #333333;
                }
			}
			&:after {
                content: '\f105';
                font-family: 'fontAwesome';
                font-size: 18px;
				margin: -1px 12px 0px 12px;
				color: #8acfef;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        margin: 15px 0 30px;
        ul {
            li {
                &:after {
                    font-size: 16px;
                    margin: -1px 8px 0;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin: 10px 0 15px;
    }
}
/* breadcrumbs */

/* page services */
.services__block1 {
    height: 445px;
    display: flex;
    position: relative;
    &:before {
        content: '';
        width: 50%;
        background: linear-gradient(to right, rgba(25, 23, 37, 0.5), rgba(25,23,37,0));
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }   
    .container {
        display: flex;
        align-items: center;
        position: relative;
        padding: 20px 15px 150px;
        z-index: 1;
    }
    .advantages__block {
        background-color: rgba(25, 23, 37, 0.7);
        width: 100%;
        min-height: 130px;
        color: #fff;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 15px;
        z-index: 1;
        h5 {
            color: #fff;
        }
        .advantages__item {
            border: none!important;
        }
    }
    h1 {
        font-size: 48px;
        color: #fff;
        margin: 0;
    }
    @media screen and (max-width: 1440px) {
        h1 {
            font-size: 36px;
        }
    }
    @media screen and (max-width: 1200px) {
        h1 {
            font-size: 30px;
        }
    }
    @media screen and (max-width: 1024px) {
        height: 375px;
        h1 {
            font-size: 26px;
        }
    }
    @media screen and (max-width: 768px) {
        height: auto;
        flex-direction: column-reverse;
        &:before {
            display: none;
        }
        h1 {
            font-size: 22px;
            br {
                display: none;
            }
        }
        .advantages__block {
            position: relative;
            background-color: transparent;
            border: none;
        }
        .container {
            background-color: rgba(25, 23, 37, 0.7);
            padding: 25px 15px 15px;
            flex-direction: column;
        }
        .btn {
            margin: 0px auto 15px;
        }
        > img {
            position: relative;
            height: auto;
        }
    }
    @media screen and (max-width: 600px) {
        h1 {
            font-size: 20px;
        }
    }
}
.services__adv {
    display: flex;
    justify-content: space-between;
    margin: 50px -15px;
    .item {
        max-width: 25%;
        padding: 0px 15px 0;
        .icon {
            width: 50px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: left top;
            }
        }
        h5 {
            font-family: $boldS;
            margin: 15px 0;
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin: 30px 0;
        .item {
            width: 50%;
            max-width: 50%;
            padding-left: 0;
        }
    }
    @media screen and (max-width: 380px) {
        .item {
            max-width: 100%;
            width: 100%;
        }
    }
}
.tariff {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 -15px;
    .tariff__item {
        width: calc(33.33% - 30px);
        padding: 40px 20px 70px;
        text-align: center;
        position: relative;
        margin: 15px;
        border: 1px solid #ecf2fb;
        .tariff__rooms {
            display: flex;
            justify-content: center;
            .room {
                width: 50px;
                height: 50px;
                border: 2px solid #3cafe5;
                border-radius: 3px;
                margin: 0 2.5px;
            }
        }
        ul {
            display: table;
            text-align: left;
            margin: 0 auto;
            li {
                margin-bottom: 10px;
            }
        }
        h5 {
            margin: 25px 0 20px;
        }
        .price {
            display: block;
            font-family: $boldS;
            font-size: 26px;
            color: #3cafe5;
            margin: 30px 0;
            .tg {
                font-weight: bold;
            }
        }
        .red-btn {
            display: flex;
            height: 70px;
            background-color: #6c1b34;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #fff;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            line-height: 1;
            &:hover {
                background-color: #3cafe5;
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .tariff__item {
            .price {
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        margin: 0 -7.5px;
        .tariff__item {
            width: calc(33.33% - 15px);
            margin: 7.5px;
        }
    }
    @media screen and (max-width: 1024px) {
        .tariff__item {
            padding: 25px 15px 50px;
            .tariff__rooms {
                .room {
                    width: 40px;
                    height: 40px;
                }
            }
            .red-btn {
                height: 50px;
            }
            .price {
                font-size: 20px;
                margin: 20px 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        justify-content: center;
        .tariff__item {
            width: calc(50% - 15px);
        }
    }
    @media screen and (max-width: 480px) {
        margin: 0;
        .tariff__item {
            width: 100%;
            margin: 7.5px 0;
        }
    }
}
.page {
    .shares__item {
        &:nth-last-of-type(-n+2) {
            margin-bottom: 20px;
        }
    }
}
/* page services */

/* page contacts */
.map {
    position: relative;
    padding: 95px 0;
    ul {
        @include ul-default;
    }
    > ymaps {
        height: 100%!important;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }
    .ymaps-2-1-76-map {
        height: 100%!important;
    }
    .map__block {
        width: 460px;
        background-color: #fff;
        padding: 20px 40px 50px;
        box-sizing: border-box;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
        h5 {
            margin: 40px 0 20px
        }
        ul {
            li {
                margin-bottom: 12px;
            }
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
    @media screen and (max-width: 1440px) {
        padding: 50px 0;
        .map__block {
            padding-bottom: 40px;
            h5 {
                margin-top: 30px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .form__block {
            .col {
                width: calc(50% - 7.5px);
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 30px 0;
        .map__block {
            width: 375px;
            padding: 10px 20px 30px;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        > ymaps {
            height: 250px!important;
            position: relative;
        }
        .map__block {
            width: 100%;
            padding: 0;
            box-shadow: none;
            margin-bottom: 15px;
        }
    }
}
.form-group {
    position: relative;
    cursor: text;
    .placeholder {
        height: 50px;
        font-family: $regular;
        color:#999999;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        line-height: 1;
        padding: 13px 30px;
        display: block;
        cursor: text!important;
        .name {
            cursor: text!important;
        }
        .star {
            color: #49b4e7;
            font-size: 20px;
            margin-left: 5px;
            cursor: text;
        }
    }
    @media screen and (max-width: 1440px) {
        .placeholder {
            height: 44px;
            padding: 12px 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        .placeholder {
            height: 38px;
            font-size: 14px;
            padding: 10px 15px;
            .star {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .placeholder {
            padding-top: 11px;
        }
    }
}
.cts {
    h3 {
        font-family: $bold;
    }
    form {
        p {
            margin-bottom: 30px;
        }
        .form__block {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .col {
                width: calc(50% - 15px);
                justify-content: space-between;
            }
        }
        input[type="text"],
        input[type="email"] {
            margin-bottom: 20px;
        }
        textarea {
            height: 191px;
        }
        .g-recaptcha {
            float: right;
        }
    }
    @media screen and (max-width: 1440px) {
        form {
            textarea {
                height: 178px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        form {
            .form__block {
                .col {
                    width: calc(50% - 7.5px);
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            p {
                margin-bottom: 20px;
            }
            textarea {
                height: 150px;
            }
            input[type="text"],
            input[type="email"] {
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 660px) {
        form {
            .form__block {
                .col {
                    width: 100%;
                }
            }
            textarea {
                height: 100px;
            }
            .g-recaptcha {
                float: none;
            }
            .btn {
                float: right;
                margin-top: -45px;
            }
        }
    }
    @media screen and (max-width: 460px) {
        form {
            .g-recaptcha {
                display: table;
                float: none;
                margin: 0 auto;
            }
            .btn {
                float: none;
                margin: 15px auto 0;
            }
        }
    }
    @media screen and (max-width: 340px) {
        form {
            .g-recaptcha {
                display: block;
                width: 280px;
                > div {
                    transform: scale(0.9);
                    transform-origin: 0 0;
                }
            }
            .btn {
                margin-top: 10px;
            }
        }
    }
}

/* page contacts */

/* page pagination */
.pagination {
    margin-top: 30px;
    ul {
        @include ul-default;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            margin: 0 5px 10px;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $boldS;
                font-size: 18px;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 3px;
                border: 1px solid #ebf1fb;
                &:hover {
                    border: 1px solid #6c1b34;
                    color: #6c1b34;
                }
            }
            &.active a {
                border: 1px solid #6c1b34;
                color: #6c1b34;
            }
            &.prev, &.next {
                a {
                    &:after {
                        content: '\f101';
                        font-family: 'fontAwesome';
                        font-size: 20px;
                    }
                }
            }
            &.prev {
                a {
                    &:after {
                        content: '\f100';
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        ul li {
            a {
                width: 30px;
                height: 30px;
                font-size: 14px;
            }
            &.prev, &.next {
                a {
                    &:after {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
/* page pagination */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 18px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 50px;
            width: 100%;
            background-color: #fff;
            font-family: $regular;
            font-size: 16px;
            margin-bottom: 15px;
            box-shadow: none;
            border: 1px solid #d3dded;
            padding: 15px 20px 14px;
            border-radius: 3px;
            &::placeholder {
                color: #999999;
            }
        }
        .palceholder {
            padding-left: 20px;
            padding-right: 20px;
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1440px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 45px;
                padding: 13px 20px 12px;  
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px 9px;  
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            padding-bottom: 120px;
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 50%;
            }
            .btn {
                margin-top: 10px;
            }
        }
    }
}

.call--btn {
    display: none;
    position: fixed;
    bottom: 100px;
    right: 40px;
    background-color: #3caee4;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 23px 5px 5px 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 2;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #3caee4;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        padding: 0;
        bottom: 30px;
        &:before,&:after {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            box-sizing: border-box;
        }
        i {
            font-size: 20px;
        }
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */